@charset "UTF-8";
.g-wrapper{
	.g-header{
		padding: 0 70px 0 0;
		.g-navi{
			.lang-change{
				position: absolute;
				top: 22px;
				right: 6px;
				margin: 0;
				li{
			        a,
			        a:link,
			        a:visited,
			        a:active{
			        	font-size:0.6rem;
						padding: 10px;
						background: #2E9DE2;
						border-radius: 4px;
						color: #fff;
						font-weight: bold;
						border: 1px solid #2E9DE2;
			        }
			        a:hover{
						background: #fff;
						color: #2E9DE2;
			        }
				}
			}
			.jaencn{
				li{
					display: none;
				        a,
				        a:link,
				        a:visited,
				        a:active{
				        	display: block;
				        	width: 100%;
				        }
				        a:hover{
				        }
				}
				.lang-tggl{
					cursor: pointer;
					display: block;
		        	font-size:0.6rem;
					padding: 10px;
					background: #2E9DE2;
					border-radius: 4px;
					color: #fff;
					font-weight: bold;
					border: 1px solid #2E9DE2;
				}
			}
			.jaencn:hover{
				.lang-tggl{
					    border-bottom-right-radius: 0;
					    border-bottom-left-radius: 0;
				}
				li{
					display: block;
				}
				.radi-ja{
			        a,
			        a:link,
			        a:visited,
			        a:active{
						border-radius: 0;
			        }
				}
				.radi-en{
			        a,
			        a:link,
			        a:visited,
			        a:active{
					    border-top-right-radius: 0;
					    border-top-left-radius: 0;
			        }
				}
			}
		}
		.g-navi__list > li a{
			font-size:0.89rem;
		}
	}
}
@media (max-width: 1437px) {
.g-wrapper{
	.g-header{
		.g-navi_sp{
			.lang-change{
				margin: 0;
				li{
			        a,
			        a:link,
			        a:visited,
			        a:active{
			        	display: block;
			        	width: 200px;
			        	margin: 30px auto 0 auto;
			        	text-align: center;
			        	font-size:0.6rem;
						padding: 10px;
						background: #2E9DE2;
						border-radius: 4px;
						color: #fff;
						font-weight: bold;
						border: 1px solid #2E9DE2;
			        }
			        a:hover{
			        }
				}
			}
		}
	}
}
}

@include media(sp){

}

