body{
	#clinic-page{
		.l-inner.l-col2{
			display: block;
		}
		.l-main.p-markup{
			width: auto;
			margin: 0;
			max-width: initial;
		}
		#clinic-page-wrap{
			@include flex(nowrap,space-between,space-between);
			.clinic-block{
				// -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
				// box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
				// margin: 0 10px;
				display: flex;
				flex-direction: column;
				.clinic-block-pdd{
					padding: 10px;
				}
				.clinic-block-img{
					height: 300px;
					width: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
				}
				h2{
					background: transparent;
					color: #000;
					font-size: 1.2rem;
					padding: 0;
					border-radius: none;
					line-height: 1.4;
					margin:10px 0;
					font-weight: bold;
				}
				h3{
					border-bottom: none;
					font-size: 1.1rem;
					padding: 0;
					line-height: 1.4;
					margin:10px 0;
					font-weight: normal;
					text-align: center;
				}
				.clinic-time{
					margin: 30px 0 30px 0;
					flex-grow: 1;
					.c-table{
						thead{
							tr{
								th,td{
									
								}
								th{

								}
								td{

								}
							}
						}
						tbody{
							tr{
								th,td{

								}
								th{

								}
								td{
									span{
										font-size: 1rem;
									}
									.close{

									}
									.close{

									}
								}
							}
						}
					}
				}
				.clinic-access{
					// margin: 0 0 30px 0;
					iframe{
						aspect-ratio: 16 / 9;
						// width: calc(50% - 10px);
						width: 100%;
						height:100%;
						margin: 0 auto;
						display: block;
					    margin-bottom: 10px;
					}
				}
				.clinic-page-tel{
					margin-top: 70px;
				}

				.clinic-page-more{

				}
				a.clinic-page-tel,
				a.clinic-page-tel:link,
				a.clinic-page-tel:visited,
				a.clinic-page-tel:active,
				a.clinic-page-more,
				a.clinic-page-more:link,
				a.clinic-page-more:visited,
				a.clinic-page-more:active{
					display: block;
					color: #67B0DE;
					border: 1px solid;
					border-radius: 5px;
					font-weight: bold;
					padding: 18px 80px;
					background: #fff;
					position: relative;
					-webkit-transition: all 0.5s;
					transition: all 0.5s;
					margin-bottom: 20px;
					background: #fff;
					-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
					text-align: center;
				}
				a.clinic-page-tel:hover,
				a.clinic-page-more:hover{
					background: #67B0DE;
					color: #fff;
				}
				a.clinic-page-tel:after,
				a.clinic-page-more:after{
					display: block;
					content: "";
					width: 21px;
					height: 6px;
					background: url(../../img/cmn/icon_link_light.svg) no-repeat top right;
					position: absolute;
					right: 40px;
					top: 29px;
				}
				a.clinic-page-tel:hover:after,
				a.clinic-page-morel:hover:after{
					background: url(../../img/cmn/icon_link_white.svg) no-repeat top right;
				}
			}
		}
	}
}
@include media(sp){
	body{
		#clinic-page{
			.l-inner.l-col2{
				display: block;
			}
			.l-main.p-markup{
				width: auto;
				margin: 0;
				max-width: initial;
			}
			#clinic-page-wrap{
				display: block;
				.clinic-block{
					margin-top: 40px;
					.clinic-block-pdd{
						padding: 0;
					}
					.clinic-block-img{
					}
					h2{
					}
					h3{
					}
					.clinic-time{
						margin: 20px 0 20px 0;
						flex-grow: initial;
						.c-table{
							thead{
								tr{
									th,td{
										
									}
									th{

									}
									td{

									}
								}
							}
							tbody{
								tr{
									th,td{

									}
									th{

									}
									td{
										span{
											font-size: inherit;
										}
										.close{

										}
										.close{

										}
									}
								}
							}
						}
					}
					.clinic-access{
						iframe{
						}
					}
					.clinic-page-tel{
						margin-top: 30px;
					}

					.clinic-page-more{

					}
					a.clinic-page-tel,
					a.clinic-page-tel:link,
					a.clinic-page-tel:visited,
					a.clinic-page-tel:active,
					a.clinic-page-more,
					a.clinic-page-more:link,
					a.clinic-page-more:visited,
					a.clinic-page-more:active{
					}
					a.clinic-page-tel:hover,
					a.clinic-page-more:hover{
					}
					a.clinic-page-tel:after,
					a.clinic-page-more:after{
					}
					a.clinic-page-tel:hover:after,
					a.clinic-page-morel:hover:after{
					}
				}

				.clinic-block:first-child{
					margin-top: 0;
				}
			}
		}
	}
}
@media screen and (min-width: 980px) {

}
@media screen and (max-width: 980px) {

}

