.company-about{
	font-size: 13px;
	a,
	a:link,
	a:visited,
	a:active{
		display: block;
		text-decoration: underline;
	}
	a:hover{
		text-decoration: none;
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}

@include media(sp){
	.company-about{
		display: none;
	}
}