
.inciflam169{
	@include flex(wrap,space-between,space-between);
	iframe{
		aspect-ratio: 16 / 9;
		width: calc(50% - 10px);
		height:100%;
		margin: 0 auto;
		display: block;
	    margin-bottom: 10px;
	}
	iframe:nth-child(2n+1) {
	    margin-right: 10px;
	}
}

@include media(sp){

	.inciflam169{
		display: block;
		iframe{
			width: 100%;
	   		margin-bottom: 40px;
		}
		iframe:nth-child(2n+1) {
		    margin-right: 0;
		}
	}

}

.cta-block{
	margin: 30px 0;
	position: relative;
	.u-pcOnly{

	}
	.u-spOnly{
		display: none;
	}
	.cta-person{
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.cta-block-wrap{
		position: relative;
		border: solid 4px #278cc3;
		border-radius: 16px;
		background: transparent;
		overflow:hidden;
		padding: 0 0 30px 0;
		.cta-block-ttl{
			background: #278cc3;
			border-radius: 0;
			text-align: center;
			color: #fff;
			font-size: 23px;
			font-weight: 700;
			padding: 10px 10px;
			line-height: 1.4;
			margin: 0 0 20px 0;
		}
		.cta-ashirai{
			display: block;
			text-align: center;
			color: #26809E;
			font-weight: 600;
			span{
				position: relative;
				display: inline;
			}
			span:after{
				content: " ";
				display: block;
				width: 2px;
				height: 20px;
				background: #26809E;
				position: absolute;
				top: -2px;
				left: -20px;
				transform:rotate(-30deg);
			}
			span:before{
				content: " ";
				display: block;
				width: 2px;
				height: 20px;
				background: #26809E;
				position: absolute;
				top: -2px;
				right: -20px;
				transform:rotate(30deg);
			}
		}

        a,
        a:link,
        a:visited,
        a:active{
        	display: block;
        	color: #fff;
        	width: 370px;
        	margin: 10px auto 30px auto;
        	border-radius: 4px;
        	border: 4px solid #fff;
        	background: #fff;
        	text-align: center;
        	position: relative;
			font-size: 18px;
			font-weight: 700;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
			span{
				display: block;
        		background: #FF7500;
        		padding: 16px 0;
        		border-radius: 4px;
			}
        	img{
        		top: 50%;
        		margin-top: -8px;
        		right: 20px;
        		position: absolute;
        		width: 16px;
        	}
        }
        a:hover{
            @include hover;
        }
	}
	.cta-coution{
		text-align: center;
		font-size: 12px;
		font-weight: 400;
	}
}


@include media(sp){
	.cta-block{
		.u-pcOnly{
			display: none;
		}
		.u-spOnly{
			display: block;
		}
		.cta-person{
			img{
				width: 150px;
			}
		}
		.cta-block-wrap{
			padding: 0 0 60px 0;
			.cta-content{
				padding: 10px;
			}
			.cta-block-ttl{
				font-size: 20px;
			}
			.cta-ashirai{
				span{
					position: relative;
					display: inline;
				}
				span:after{
					width: 2px;
					height: 20px;
					top: -2px;
					left: -20px;
				}
				span:before{
					width: 2px;
					height: 20px;
					top: -2px;
					right: -20px;
				}
			}

	        a,
	        a:link,
	        a:visited,
	        a:active{
	        	display: block;
	        	color: #fff;
	        	width: 95%;
				font-size: 15px;
				font-weight: 700;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				span{
				}
	        	img{
	        		top: 50%;
	        		margin-top: -8px;
	        		right: 8px;
	        		position: absolute;
	        		width: 16px;
	        	}
	        }
	        a:hover{
	            @include hover;
	        }
		}
		.cta-coution{
			text-align: left;
			width: 70%;
			font-size: 12px;
			font-weight: 400;
		}
	}
}
#clinic-ttl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
}
#clinic-ttl h1 {
  width: 100%;
  display: block;
  padding: 0 10px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  line-height: 2;
  margin: 0;
}

@media only screen and (max-width:768px) {
  #clinic-ttl h1 {
    font-size: 18px;
  }
}
#page-sidebar-2{
	#header-news{
		// max-width: 1200px;
		padding: 10px 15px;
		margin: 0 auto;
		position: absolute;
		bottom: 20px;
		right: 20px;
		z-index: 2;
		line-height: 1.7;
		.news-txt{
			background: #fff;
			border-radius: 6px;
			width: 350px;
			padding: 10px 20px;
			@include flex(nowrap,left,space-between);
			align-items: center;
			.date-block{
				position: relative;
				text-align: center;
				.date-year{
					display: block;
					font-size: 14px;
				}
				.date-day{
					display: block;
					font-size: 12px;
				}
			}
			.date-block:after{
				content: " ";
				@include center;
				width: 100%;
				background: #000;
				display: block;
				width: 100%;
				height: 1px;
			}
			.news-txt-ttl{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding: 0 0 0 20px;
				font-weight: bold;
				.news-txt-icon{
					font-size: 14px;
					color: #f5a800;
					font-weight: bold;
					padding-right: 6px;
				}
			}
		}
        a.news-txt,
        a.news-txt:link,
        a.news-txt:visited,
        a.news-txt:active{
        }
        a.news-txt:hover{
            @include hover;
        }
	}
	.c-pagefv{
		height: auto;
		line-height: 0;
		.ojf{

		}
		.title{
			width: 100%;
			display: block;
			// left: 0;
			// -ms-transform: translate(0%, -50%);
			// -webkit-transform: translate(0%, -50%);
			// transform: translate(0%, -50%);
			// background: rgba(0, 0, 0 , 0.5);
			@include center;
			padding: 20px 30px;
			span{
				font-family: "Sawarabi Mincho";
			}
		}
		#top-movie{
			width: 100%;
			position: relative;
			z-index: 1;
			video {
				width: 100%;
			}
		}
	}
	.c-pagefv:after{
		content: " ";
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		@include center;
		z-index: 1;
	}
	.carender-btn{
		@include flex(nowrap,space-between,space-between);
		align-items: center;
		align-items: stretch;
		margin-top: 20px;
        a,
        a:link,
        a:visited,
        a:active{
			align-items: center;
        	display: flex;
        	text-align: center;
        	font-size: 1rem;
        	padding: 18px 4px;
        	margin: 0 10px;
        }
        a:hover{
            @include hover;
        }
	}
	#tel-btn{
		width: 350px;
		margin: 20px auto 0 auto;
        a,
        a:link,
        a:visited,
        a:active{
			display: block;
			color: #67B0DE;
			border: 1px solid;
			border-radius: 5px;
			font-weight: bold;
			padding: 18px 10px;
			background: #fff;
			position: relative;
			-webkit-transition: all 0.5s;
			transition: all 0.5s;
			margin-bottom: 20px;
			background: #fff;
			-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
			text-align: center;	
			font-size: 28px;
			svg{
				width: 20px;
				padding-right: 10px;
				fill: #67B0DE;
				@include trans;
			}
        }
        a:hover{
			background: #67B0DE;
			color: #fff;
			svg{
				width: 20px;
				padding-right: 10px;
				fill: #fff;
			}
		}
	}
	.l-inner.l-col2{
		padding-top: 0;
	}
}


@include media(sp){

	#page-sidebar-2{
		#header-news{
			padding: 0;
			right: auto;
			left: 50%;
			bottom: -110px;
			-ms-transform: translate(-50%, 0%);
			-webkit-transform: translate(-50%, 0%);
			transform: translate(-50%, 0%);
			.news-txt{
				.date-block{
					.date-year{
					}
					.date-day{
					}
				}
				.date-block:after{
				}
				.news-txt-ttl{
					.news-txt-icon{
					}
				}
			}
	        a.news-txt,
	        a.news-txt:link,
	        a.news-txt:visited,
	        a.news-txt:active{
			border: 1px solid #e0e0e0;
			width: 300px;
	        }
	        a.news-txt:hover{
	        }
		}
		.c-pagefv{
			// height: 55vh;
			overflow: visible;
			.ojf{

			}
			.title{
				padding: 20px 10px;
				h1{
					font-size: clamp(1.3rem, 1.03rem + 0.85vw, 1.438rem);
				}
			}
		}
		.carender-btn{
			width: 100%;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	        a,
	        a:link,
	        a:visited,
	        a:active{
	        	margin: 10px 0;
	        	font-size: 14px;
	        	width: 100%;
	        }
	        a:hover{
	        }
		}
		#tel-btn{
			width: 100%;
	        a,
	        a:link,
	        a:visited,
	        a:active{
				font-size: 20px;
				svg{
					width: 18px;
				}
	        }
	        a:hover{
				svg{
					width: 18px;
				}
			}
		}
		.l-inner.l-col2{
			padding-top: 80px;
		}
		.c-table{
			width: auto;
			thead{
				tr{
					th,td{
						font-size: 10px;
					}
					th{
						width: 10%;
					}
					td{

					}
				}
			}
			tbody{
				tr{
					th,td{
						font-size: 10px;
						.open,.close{
							font-size: 10px;
						}
					}
					th{
						width: 10%;
					}
					td{

					}
				}
			}
		}
		.schdule-cption{
			font-size: 10px;
		}
	}
}

#customers{
	ul.p-tobira._type3{
		@include flex(wrap,left,space-between);
		align-items: center;
		align-items: stretch;
	}
	.p-tobira._type3 li {
		width: 32%;
		display: block;
		padding-bottom: 20px;
		overflow: hidden;
		border-radius: 10px;
		-webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
		background: #fff url(../../img/cmn/icon_link.svg) no-repeat bottom 10px right 10px;
		float: none;
		margin-right: 19px;
	}
	.p-tobira._type3 li:nth-child(3n){
		margin-right: 0;
	}
	.p-tobira._type3 li a{
		-webkit-box-shadow: none;
		box-shadow: none;
		background: transparent;
		padding: 10px;
		p{
			padding: 20px 0;
		}
	}
	.p-tobira._type3 li .customers-text{
		padding: 20px;
	}
}
@include media(sp){
	#customers{
		ul.p-tobira._type3{
			display: block;
		}
		.p-tobira._type3 li {
			width: 100%;
		}
		.p-tobira._type3 li a{
			p{
			}
		}
		.p-tobira._type3 li .customers-text{
		}
	}
}

@include media(sp){
body{
}
}

#youtuber{
	.lead-ttl{
		text-align: center;
		font-size: 40px;
		font-weight: 700;
	}
	.lead-txt{
		font-weight: 500;
		text-align: center;
	}
	#youtuber-block{
		margin-top: 30px;
		@include flex(wrap,left,space-between);
		align-items: center;
		align-items: stretch;
		.youtuber-detail{
			padding: 10px 5px;
			width: 33.3333333333%;
			.youtuber-img{
				position: relative;
				padding: 0 0 20px 0;
				.play-btn{
					@include center;
					img{
						width: 50px;
						height: auto;
					}
				}
			}
			h4{
				margin: 0 0 20px 0;
				font-size: 16px;
				font-weight: 600;
				text-align: center;
			}
			.youtuber-ttl{
				font-size: 16px;
				font-weight: 700;
				margin: 0 0 20px 0;
			}
			.youtuber-txt{

			}
		}
	}
}

@include media(sp){
#youtuber{
	.lead-ttl{
		font-size: 20px;
	}
	.lead-txt{
		font-weight: 500;
		text-align: center;
	}
	#youtuber-block{

		.youtuber-detail{
			width: 50%;
			.youtuber-img{
				.play-btn{
					@include center;
					img{
						width: 30px;
						height: auto;
					}
				}
			}
			h4{
				font-size: 14px;
				font-weight: bold;
				text-align: center;
			}
			.youtuber-ttl{
				font-size: 14px;
			}
			.youtuber-txt{

			}
		}
	}
}
}
#about-sales-img{
}
@include media(sp){
	#about-sales-img{
		width: 100%;
	}
}
@media screen and (min-width: 980px) {

}
@media screen and (max-width: 980px) {

}


@media not all and (max-width: 768px) {
	.l-inner{
	    .u-top_clinicbox{
	        .card_child{
	            width: 49%;
	            max-width: initial;
	        }
	    }
	}
}